var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticStyle: { padding: "184px 0px 0px", "margin-top": "-3%" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticStyle: { padding: "0px" }, attrs: { cols: "12" } },
            [_c("MensagensNavBar")],
            1
          ),
          _c(
            "v-col",
            { staticStyle: { padding: "32px 0px 0px" }, attrs: { cols: "6" } },
            [
              _c("h2", { staticClass: "title titleColor--text" }, [
                _vm._v("Mensagens e-CAC"),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-row justify-end",
              staticStyle: { padding: "32px 28px 0px" },
              attrs: { cols: "6" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn-ag-secondary",
                  attrs: { to: "/cadastros-gerenciais/configuracoes-ecac" },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-cog-outline"),
                  ]),
                  _vm._v(" Configurações "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticStyle: { "margin-top": "8px", padding: "0px 0px 0px" },
              attrs: { cols: "6" },
            },
            [
              _c("p", { staticClass: "subtitle" }, [
                _vm._v(
                  "Gerenciamento das mensagens do e-CAC e notificação automática"
                ),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              staticStyle: {
                "margin-top": "8px",
                padding: "0px 0px 0px",
                "margin-left": "-27px",
              },
              attrs: { cols: "6" },
            },
            [
              _vm.lastProcessDate
                ? _c("p", { staticClass: "subtitle-link" }, [
                    _c(
                      "button",
                      {
                        staticStyle: { color: "#2d5796" },
                        on: {
                          click: function ($event) {
                            return _vm.openProcessesModal()
                          },
                        },
                      },
                      [
                        _c("v-icon", { staticStyle: { color: "#2d5796" } }, [
                          _vm._v("mdi-refresh"),
                        ]),
                        _vm._v(" Atualização realizada "),
                      ],
                      1
                    ),
                    _vm._v(" em " + _vm._s(_vm.lastProcessDate) + " "),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c("ModalHistoryUpdate", {
        ref: "modalHistoryUpdate",
        attrs: { "update-historic": _vm.updateHistoric },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }