<template>
  <v-main style="padding: 184px 0px 0px; margin-top: -3%">
    <v-row>
      <v-col cols="12" style="padding: 0px">
        <MensagensNavBar />
      </v-col>
      <v-col cols="6" style="padding: 32px 0px 0px">
        <h2 class="title titleColor--text">Mensagens e-CAC</h2>
      </v-col>
      <v-col cols="6" class="d-flex flex-row justify-end" style="padding: 32px 28px 0px">
        <v-btn to="/cadastros-gerenciais/configuracoes-ecac" class="btn-ag-secondary">
          <v-icon left>mdi-cog-outline</v-icon> Configurações
        </v-btn>
      </v-col>
      <v-col cols="6" style="margin-top: 8px; padding: 0px 0px 0px">
        <p class="subtitle">Gerenciamento das mensagens do e-CAC e notificação automática</p>
      </v-col>
      <v-col cols="6" style="margin-top: 8px; padding: 0px 0px 0px; margin-left: -27px">
        <p v-if="lastProcessDate" class="subtitle-link">
          <button style="color: #2d5796" @click="openProcessesModal()">
            <v-icon style="color: #2d5796">mdi-refresh</v-icon> Atualização realizada
          </button>
          em {{ lastProcessDate }}
        </p>
      </v-col>
    </v-row>
    <ModalHistoryUpdate ref="modalHistoryUpdate" :update-historic="updateHistoric" />
  </v-main>
</template>
<script>
import IntegraContadorService from '@/services/integraContadorService.js';

export default {
  name: 'HeaderMessage',
  components: {
    MensagensNavBar: () => import('./MensagensNavBar.vue'),
    ModalHistoryUpdate: () => import('./ModalHistoryUpdate.vue'),
  },

  data() {
    return {
      lastProcessDate: null,
      updateHistoric: null,
    };
  },

  mounted() {
    this.setLastProcessUpdate();
  },

  methods: {
    async setLastProcessUpdate() {
      await this.getProccesses();
      if (this.updateHistoric && this.updateHistoric.length > 0) {
        this.lastProcessDate = this.formatRuleDate(this.updateHistoric[0].startedAt);
      }
    },

    async openProcessesModal() {
      this.getProccesses();
      this.$refs.modalHistoryUpdate.$emit('open');
    },

    async getProccesses() {
      let response = await IntegraContadorService.processes.get();
      this.updateHistoric = response.data.content;
    },

    formatRuleDate(date) {
      let momment = date.split('T');
      const [year, month, day] = momment[0].split('-');
      const [hour, minute] = momment[1].split(':');
      return `${day}/${month}/${year}` + ' às ' + `${hour}:${minute}`;
    },
  },
};
</script>
<style scoped>
.title {
  height: 43px;
  width: 791px;
  left: 0px;
  top: 0px;
  font-family: 'Roboto', sans-serif;
  font-size: 32px !important;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--v-primary-base);
}
.subtitle {
  height: 24px;
  width: 921px;
  left: 0px;
  top: 51px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #575767;
}
.subtitle-link {
  float: right;
  color: #575767;
}
.subtitle-link a {
  text-decoration: none;
}
</style>
